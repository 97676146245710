import React from "react";

function FooterElement() {
  return (
    <div className="footer-component">
      <p className="footer-component-text">Made with ❤️ and ☕</p>
    </div>
  );
}

export default FooterElement;
